export function createStaticProductControls(product) {
  const unitPrice = `Unit Price: $ ${product.price}`;
  const controls = [
    {
      controlType: "ListItemText",
      controlProps: {
        primary: product.productName
      }
    },
    {
      controlType: "Typography",
      controlProps: {
        variant: "subtitle2",
        children: unitPrice
      }
    }
  ];
  return controls;
}

export function calculateSubAmount(item) {
  return item && item.quantity && item.price
    ? Math.round(item.price * item.quantity * 100) / 100
    : 0;
}

export function getTotalAmountWithoutDiscount(orderItems) {
  return orderItems
    ? Math.round(
        orderItems.reduce((p, c) => p + c.price * c.quantity, 0) * 100
      ) / 100
    : 0;
}

export function getTotalAmount(order, PromoCodes, PromoRules) {
  let totalAmount = getTotalAmountWithoutDiscount(order.items);
  if (order.promoCode && PromoCodes.includes(order.promoCode)) {
    totalAmount -= getSavingByPromoCode(order, PromoRules);
  }
  return Math.round(totalAmount * 100) / 100;
}

export function getSavingByPromoCode(order, PromoRules) {
  const aCodes = PromoRules.amountDiscount;
  const uCodes = PromoRules.unitPriceDiscount;
  const promoCode = order.promoCode;
  if (promoCode in aCodes) {
    return (
      getTotalAmountWithoutDiscount(order.items) * aCodes[promoCode].discount
    );
  } else if (promoCode in uCodes) {
    const item = order.items.find(
      item => item.productId === uCodes[promoCode].productId
    );
    return item && item.quantity
      ? item.quantity * uCodes[promoCode].savingPerUnit
      : 0;
  }
  return 0;
}

export function getAvailablePromoCode(order, PromoRules) {
  const codes = [];
  if (order.items && order.items.length > 0 && PromoRules) {
    const amountWithoutDiscont = getTotalAmountWithoutDiscount(order.items);
    const aCodes = PromoRules.amountDiscount;
    Object.keys(aCodes).forEach(code => {
      const promo = aCodes[code];
      if (amountWithoutDiscont > promo.minAmount) {
        codes.push(code);
      }
    });

    const uCodes = PromoRules.unitPriceDiscount;
    Object.keys(uCodes).forEach(code => {
      const promo = uCodes[code];
      const rules = promo.rules;
      if (
        order.items.some(item => item.productId === promo.productId) &&
        rules.every(rule =>
          order.items.some(
            item =>
              item.productId === rule.productId && item.quantity >= rule.minQty
          )
        )
      ) {
        codes.push(code);
      }
    });
  }
  return codes;
}

export function removeNonAvailablePromoCode(order, availableCodes) {
  if (
    order &&
    order.promoCode &&
    availableCodes &&
    (availableCodes.length < 1 ||
      (availableCodes.length > 0 && !availableCodes.includes(order.promoCode)))
  ) {
    order.promoCode = "";
  }
}
