import React from "react";

import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";

import DynamicListItemComponent from "../../components/DynamicListItemComponent";
import {
  calculateSubAmount,
  getTotalAmount,
  createStaticProductControls
} from "../../utils/checkoutUtil";
import { PromoCodes, PromoRules } from "../../backend/data";

function renderPromoCode(order, classes) {
  return order.promoCode ? (
    <ListItem>
      <ListItemText variant="subtitle1" primary="" />
      <Typography className={classes.promoCode} variant="subtitle1">
        {"Promo: "}
        {order.promoCode}
      </Typography>
    </ListItem>
  ) : null;
}

function renderTotalAmount(order, classes) {
  return (
    <ListItem className={classes.listItem}>
      <ListItemText
        className={classes.total}
        variant="h6"
        primary="Total Amount :  "
      />
      <Typography className={classes.numberItem} variant="h6">
        $ {getTotalAmount(order, PromoCodes, PromoRules)}
      </Typography>
    </ListItem>
  );
}

function createProductCheckboxItem(product, order, classes) {
  const _controls = createStaticProductControls(product, classes);
  const subAmount = calculateSubAmount(product, PromoRules);
  const controls = [
    ..._controls,
    {
      controlType: "Typography",
      controlProps: {
        variant: "subtitle1",
        children: `Qty: ${product.quantity}`
      }
    },
    {
      controlType: "Typography",
      controlProps: {
        variant: "subtitle1",
        children: `Sub: $ ${subAmount}`
      }
    }
  ];

  const props = { product, controls, classes, isReview: true };
  return <DynamicListItemComponent key={product.productId} {...props} />;
}

function Review(props) {
  const { classes, order } = props;

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Review Your Order
      </Typography>
      <List disablePadding>
        {order &&
          order.items &&
          order.items.map(product =>
            createProductCheckboxItem(product, order, classes)
          )}
        {renderPromoCode(order, classes)}
        {renderTotalAmount(order, classes)}
      </List>
    </React.Fragment>
  );
}
export default Review;
