const Products = [
  {
    productId: "wf",
    productName: "Workflow",
    price: 199.99
  },
  {
    productId: "docgen",
    productName: "Document Generation",
    price: 9.99
  },
  {
    productId: "form",
    productName: "Form",
    price: 99.99
  }
];

const PromoCodes = ["RRD4D32", "44F4T11", "FF9543D1", "YYGWKJD"];

const PromoRules = {
  amountDiscount: {
    RRD4D32: {
      promoCode: "RRD4D32",
      description: "10% discount for orders above $1000 (pre-discount)",
      minAmount: 1000,
      discount: 0.1
    },
    "44F4T11": {
      promoCode: "44F4T11",
      description: "15% discount for orders above $1500 (pre-discount)",
      minAmount: 1500,
      discount: 0.15
    }
  },
  unitPriceDiscount: {
    FF9543D1: {
      promoCode: "FF9543D1",
      description:
        "Reduces the docgen price to $8.99 a unit when at least 10 documents are purchased",
      productId: "docgen",
      savingPerUnit: 1,
      rules: [
        {
          productId: "docgen",
          minQty: 10
        }
      ]
    },
    YYGWKJD: {
      promoCode: "YYGWKJD",
      description:
        "Reduces the form price to $89.99 a unit when at least 1 wf is purchased",
      productId: "form",
      savingPerUnit: 10,
      rules: [
        {
          productId: "wf",
          minQty: 1
        }
      ]
    }
  }
};

export { Products, PromoCodes, PromoRules };
