import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";
import DynamicListItemComponent from "../../components/DynamicListItemComponent";

import { createStaticProductControls } from "../../utils/checkoutUtil";

function createProductCheckboxItem(product, updateProduct, classes) {
  const staticControls = createStaticProductControls(product);
  const controls = [
    ...staticControls,
    {
      controlType: "TextField",
      controlProps: {
        onChange: e => updateProduct(product.productId, e),
        label: "Qty",
        type: "number",
        name: "quantity",
        className: "listItem",
        value: product.quantity,
        inputProps: { min: "1", step: "1" }
      }
    }
  ];

  const props = { product, controls, classes };
  return <DynamicListItemComponent key={product.productId} {...props} />;
}

function ChangeQty(props) {
  const { classes, updateProduct, order } = props;
  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Change Quantity of Product
      </Typography>
      <List disablePadding>
        {order &&
          order.items &&
          order.items.map(product =>
            createProductCheckboxItem(product, updateProduct, classes)
          )}
      </List>
    </React.Fragment>
  );
}

ChangeQty.propTypes = {
  classes: PropTypes.object.isRequired,
  updateProduct: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired
};

export default ChangeQty;
