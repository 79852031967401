import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";

function Thankyou(props) {
  const { titleText, contentText, handleReset ,classes} = props;
  return (
    <div>
      <Typography variant="h5" gutterBottom>
        {titleText}
      </Typography>
      <Typography variant="subtitle1">{contentText}</Typography>
      <Button onClick={handleReset} className={classes.resetButton} >
                    Try Again
      </Button>
  
    </div>
  );
}

Thankyou.propTypes = {
  titleText: PropTypes.string.isRequired,
  contentText: PropTypes.string.isRequired,
  handleReset: PropTypes.func,
  classes: PropTypes.object
};

export default Thankyou;
