import React from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";
import Paper from "@material-ui/core/Paper";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import ProductList from "./ProductList";
import Review from "./Review";
import ChangeQty from "./ChangeQty";
import Thankyou from "../../components/Thankyou";
import PromoCode from "../../components/PromoCode";
import { Products, PromoRules } from "../../backend/data";
import {
  getAvailablePromoCode,
  removeNonAvailablePromoCode
} from "../../utils/checkoutUtil";

const styles = theme => ({
  paper: {
    marginTop: theme.spacing.unit * 3,
    marginBottom: theme.spacing.unit * 3,
    padding: theme.spacing.unit * 2,
    [theme.breakpoints.up(600 + theme.spacing.unit * 3 * 2)]: {
      marginTop: theme.spacing.unit * 6,
      marginBottom: theme.spacing.unit * 6,
      padding: theme.spacing.unit * 3
    }
  },
  stepper: {
    padding: `${theme.spacing.unit * 3}px 0 ${theme.spacing.unit * 5}px`
  },
  buttons: {
    display: "flex",
    justifyContent: "flex-end"
  },
  button: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit
  },
  resetButton: {
    marginTop: theme.spacing.unit * 3,
    marginLeft: theme.spacing.unit,
    color: "blue"
  },
  listItem: {
    padding: `${theme.spacing.unit}px 0`,
    margin: `0 ${theme.spacing.unit * 2}px`
  },
  numberItem: {
    padding: " 0 25px",
    margin: " 0 25px"
  },
  promoCode: {
    fontWeight: "700",
    padding: " 0 0"
  },
  total: {
    fontWeight: "900",
    padding: " 0 0"
  },
  title: {
    marginTop: theme.spacing.unit * 2
  }
});

const steps = ["Picking Products", "Change Quantity", "Review your order"];

class Checkout extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      nextEnabled: false,
      activeStep: 0,
      order: { items: [] },
      availableCodes: [] // available promode codes
    };

    this.toggleProduct = this.toggleProduct.bind(this);
    this.updateProduct = this.updateProduct.bind(this);
    this.getStepContent = this.getStepContent.bind(this);
    this.updateOrder = this.updateOrder.bind(this);
  }

  handleNext = () => {
    this.setState(state => ({
      state,
      activeStep: state.activeStep + 1
    }));
  };

  handleBack = () => {
    this.setState(state => ({
      ...state,
      activeStep: state.activeStep - 1
    }));
  };

  handleReset = () => {
    this.setState({
      nextEnabled: false,
      activeStep: 0,
      order: { items: [] },
      availableCodes: []
    });
  };
  getStepContent(step, classes) {
    const { order } = this.state;
    // eslint-disable-next-line
    switch (step) {
      case 0:
        return (
          <ProductList
            toggleProduct={this.toggleProduct}
            order={order}
            classes={classes}
          />
        );
      case 1:
        return (
          <ChangeQty
            updateProduct={this.updateProduct}
            order={order}
            classes={classes}
          />
        );
      case 2:
        return <Review order={order} classes={classes} />;
    }
  }

  updateProduct(productId, e) {
    if (productId && e && e.target && e.target.name) {
      const { order } = this.state;
      const item = order.items.find(i => i.productId === productId);
      const name = e.target.name;
      item[name] = Math.round(e.target.value);
      this.setState(null, () => {
        const { order } = this.state;
        const availableCodes = getAvailablePromoCode(
          this.state.order,
          PromoRules
        );
        removeNonAvailablePromoCode(order, availableCodes);
        this.setState({
          ...this.state,
          order,
          availableCodes
        });
      });
    }
  }

  updateOrder(e) {
    if (e && e.target && e.target.name) {
      const { order } = this.state;
      order[e.target.name] = e.target.value;
      this.setState(null, () => {
        this.setState({
          ...this.state,
          order
        });
      });
    }
  }

  toggleProduct(productId, e) {
    if (productId && e) {
      const { order } = this.state;
      let itemIndex = -1;
      if (order.items.length > 0) {
        itemIndex = order.items.findIndex(item => item.productId === productId);
        if (itemIndex > -1) {
          order.items.splice(itemIndex, 1);
        }
      }
      if (itemIndex < 0) {
        const _product = Products.find(p => p.productId === productId);
        if (_product) {
          order.items.push({ ..._product, quantity: 1 });
        }
      }

      this.setState(null, () => {
        const nextEnabled =
          this.state.order &&
          this.state.order.items &&
          this.state.order.items.length > 0
            ? true
            : false;

        const availableCodes = getAvailablePromoCode(order, PromoRules);
        removeNonAvailablePromoCode(order, availableCodes);

        this.setState({
          ...this.state,
          order,
          nextEnabled,
          availableCodes
        });
      });
    }
  }

  generateThankyouContent() {
    const d = new Date();
    const orderNumber = `${d.getFullYear()}${d.getMonth()}${d.getDay()}${d.getHours()}${d.getMinutes()}${d.getSeconds()}`;
    return `Your order number is No. ${orderNumber}. We have emailed your order confirmation, and will send you an update when your order has shipped.`;
  }

  renderPromoCode(activeStep, availableCodes, order, classes) {
    return activeStep === 1 && availableCodes && availableCodes.length > 0 ? (
      <PromoCode
        classes={classes}
        onChange={this.updateOrder}
        availableCodes={availableCodes}
        order={order}
      />
    ) : null;
  }

  render() {
    const { classes } = this.props;
    const { activeStep, nextEnabled, availableCodes, order } = this.state;

    return (
      <Paper className={classes.paper}>
        <Typography component="h1" variant="h4" align="center">
          Checkout
        </Typography>
        <Stepper activeStep={activeStep} className={classes.stepper}>
          {steps.map(label => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
        <React.Fragment>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Thankyou
                titleText=" Thank you for your order."
                contentText={this.generateThankyouContent()}
                handleReset={this.handleReset}
                classes={classes}
              />
            </React.Fragment>
          ) : (
            <React.Fragment>
              {this.getStepContent(activeStep, classes)}
              {this.renderPromoCode(activeStep, availableCodes, order, classes)}
              <div className={classes.buttons}>
                {activeStep !== 0 && (
                  <Button onClick={this.handleBack} className={classes.button}>
                    Back
                  </Button>
                )}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={this.handleNext}
                  className={classes.button}
                  disabled={!nextEnabled}
                >
                  {activeStep === steps.length - 1 ? "Place order" : "Next"}
                </Button>
              </div>
            </React.Fragment>
          )}
        </React.Fragment>
      </Paper>
    );
  }
}

Checkout.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(Checkout);
