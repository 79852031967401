import React from "react";
import PropTypes from "prop-types";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import NativeSelect from "@material-ui/core/NativeSelect";
import Input from "@material-ui/core/Input";

function PromoCode(props) {
  const { classes, onChange, availableCodes, order } = props;

  return (
    <ListItem className={classes.listItem}>
      <ListItemText primary="Promo Code: " />
      <NativeSelect
        value={order.promoCode}
        onChange={onChange}
        input={<Input name="promoCode" id={"promoCodeInput"} />}
      >
        <option value="">Select ... </option>
        {availableCodes.map((option, i) => (
          <option key={option + i.toString()} value={option}>
            {option}
          </option>
        ))}
      </NativeSelect>
    </ListItem>
  );
}

PromoCode.propTypes = {
  classes: PropTypes.object.isRequired,
  order: PropTypes.object.isRequired,
  availableCodes: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired
};

export default PromoCode;
