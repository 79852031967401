import React from "react";
import PropTypes from "prop-types";

import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Typography from "@material-ui/core/Typography";
import Checkbox from "@material-ui/core/Checkbox";
import TextField from "@material-ui/core/TextField";

function componentFactory(control, product, idx, classes) {
  const { controlType, controlProps } = control;
  const { onChange } = controlProps;
  // eslint-disable-next-line
  switch (controlType) {
    case "Typography":
      return (
        <Typography
          className={classes.listItem}
          key={product.productId + controlType + idx}
          {...controlProps}
        />
      );
    case "ListItemText":
      return (
        <ListItemText
          className={classes.listItem}
          key={product.productId + controlType + idx}
          {...controlProps}
        />
      );
    case "Checkbox":
      return (
        <Checkbox
          className={classes.listItem}
          key={product.productId + controlType + idx}
          {...controlProps}
        />
      );
    case "TextField":
      return (
        <TextField
          onChange={e => onChange(product.productId, e)}
          className={classes.listItem}
          key={product.productId + controlType + idx}
          {...controlProps}
        />
      );
  }
}

function DynamicListItemComponent(props) {
  const { classes, product, controls } = props;
  return (
    <ListItem className={classes.listItem} key={product.productId}>
      {controls &&
        controls.map((control, idx) =>
          componentFactory(control, product, idx.toString(), classes)
        )}
    </ListItem>
  );
}

DynamicListItemComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  product: PropTypes.object.isRequired
};
export default DynamicListItemComponent;
