import React from "react";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";
import List from "@material-ui/core/List";

import DynamicListItemComponent from "../../components/DynamicListItemComponent";
import { createStaticProductControls } from "../../utils/checkoutUtil";
import { Products } from "../../backend/data";

function createProductCheckboxItem(
  product,
  toggleProduct,
  orderItems,
  classes
) {
  const checked =
    orderItems &&
    orderItems.length > 0 &&
    orderItems.find(p => p.productId === product.productId)
      ? true
      : false;

  const staticControls = createStaticProductControls(product);
  const controls = [
    ...staticControls,
    {
      controlType: "Checkbox",
      controlProps: {
        onChange: e => toggleProduct(product.productId, e),
        checked
      }
    }
  ];

  const props = { product, controls, checked, classes };
  return <DynamicListItemComponent key={product.productId} {...props} />;
}

function ProductList(props) {
  const { classes, toggleProduct, order } = props;

  return (
    <React.Fragment>
      <Typography variant="h6" gutterBottom>
        Pick Your Favorite Products
      </Typography>
      <List disablePadding>
        {Products.map(product =>
          createProductCheckboxItem(
            product,
            toggleProduct,
            order.items,
            classes
          )
        )}
      </List>
    </React.Fragment>
  );
}

ProductList.propTypes = {
  toggleProduct: PropTypes.func.isRequired,
  order: PropTypes.object.isRequired
};

export default ProductList;
